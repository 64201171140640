<template>
    <div class="waitingSign waitingSignWarp">
        <header-site-info :site-name="siteInfo.stationName"
                          :site-no="siteInfo.areaNo"
                          :site-depart="siteInfo.deptName"
        ></header-site-info>
        <div class="content">
            <van-field label="站点状态" :value="siteInfo.stationStatus" readonly input-align="right"/>
            <van-field label-width="150px" label="站点管理责任人" :value="siteInfo.marketerName" readonly input-align="right"/>
            <van-field label="合作银行" :value="siteInfo.cooperationBank" readonly input-align="right"/>
            <van-field label="推荐码" :value="siteInfo.stationCode" readonly input-align="right"/>
        </div>
    </div>
</template>

<script>
    import { getSiteByStationNo } from '@/utils/api'
    import HeaderSiteInfo from "../../components/siteInfo/headerSiteInfo";
    import {uwStatisticAction} from "../../utils/util";
    export default {
        name: "siteBriefInfo",
        components: {HeaderSiteInfo},
        data() {
            return {
                logoImg: require('../../assets/img/logo_f.png'),
                checked: false,
                stepDesc: '',
                signed: false,
                siteInfo: {},
                villageCode: '',
                redirectToSiteInfoPage: null,
                stationNo:'',
                uniqueNo:''
            }
        },
        mounted(){
            this.getsiteInfo()
            uwStatisticAction('/site/siteBriefInfo','站长——我的——站点信息')
        },
        methods:{
            getsiteInfo() {
                console.log('djhkfkjh')
                getSiteByStationNo({
                    stationNo: this.$route.query.stationNo
                }).then(res=>{
                    if(res.code ==200){
                        if(res.data && res.data.businessCode ==200){
                            this.siteInfo = res.data.data
                        }
                    }
                })
            },

        }
    }
</script>

<style lang="less" scoped>
    .waitingSign{
        height: 100%;
        font-size: 0.3rem;
        box-sizing: border-box;
        padding-top: 0.3rem;
        .flex{
            display: flex;
        }
        .title{
            align-items: center;
            justify-content: space-between;
            padding: 0.2rem 0.3rem;
            border-top: 0.1rem solid #3F7C53;
            background: #fff;
            .logo-img{
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 0.3rem;
            }
            .title_left{
                align-items: center;
            }
            .title_mid{
                margin-left: 0.3rem;
            }
        }
        .content{
            margin-top: 20px;
        }
        .suggestion{
            padding: 0.6rem 1rem;
            span{
                color: rgb(25, 137, 250);
                text-decoration: underline;
            }
        }
    }
</style>
<style>
    .waitingSignWarp .van-cell{
        padding: 14px 15px ;
        border-bottom: 1px solid #F7F8F9;
    }
    .waitingSignWarp .van-cell .van-field__label{

        font-size: 17px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #333333;
    } .waitingSignWarp .van-cell .van-cell__value{

          font-size: 17px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 600;
          color: #333333;
      }
</style>
